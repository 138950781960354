/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State, Action} from 'vuex-class'
import { ModelAccountState } from '@/store/modelAccount/types'

// const modelState = namespace('modelAccount');
const namespace: string = 'modelAccount';

export default class ModelAccountMyAccountNotificationsPage extends Vue {
	@State('modelAccount', {namespace}) modelAccount: ModelAccountState | undefined;
	@Action('updateModelPersonal', {namespace}) updateModelPersonal: any;
	@Action('getModelPersonal', {namespace}) getModelPersonal: any;
	@Getter('getModelLogin', {namespace}) getModelLogin: any;
	@Getter('getModelEmailNotification', {namespace}) getModelEmailNotification: any;
	@Getter('getModelSpecialOffers', {namespace}) getModelSpecialOffers: any;
	@Mutation('setModelEmailNotification', {namespace}) setModelEmailNotification: any;
	@Mutation('setModelSpecialOffers', {namespace}) setModelSpecialOffers: any;

	systemNotifications: boolean  = true;
	specialOffers: boolean = true;

	async setUpdate(key: string) {
		switch(key){
			case 'notification':
				this.setModelEmailNotification(this.systemNotifications);
				await this.updateModelPersonal({
						emailNotification: this.systemNotifications
				});
				break;
			case 'offer':
				this.setModelSpecialOffers(this.specialOffers);
				await this.updateModelPersonal({
					specialOffers: this.specialOffers
				})
				break;
			default:
				break;
		}
	}

	mounted() {
		this.getModelPersonal();
		this.systemNotifications = this.getModelEmailNotification;
		this.specialOffers = this.getModelSpecialOffers;
	}
}
